import React from 'react';
import ReactDOM from 'react-dom';

// import { createBrowserHistory } from 'history'
import { GlobalDebug } from 'utils';
import TagManager from 'react-gtm-module';
// import Router from 'navigation/router'
import * as serviceWorker from './serviceWorker';
import Tawk from './scripts/tawk';
import App from './app';

// app styles
import './global.scss';

// const history = createBrowserHistory()
GlobalDebug(false);

if (process.env.NODE_ENV === 'development' && true) {
  GlobalDebug(true);
}

const tagManagerArgs = {
  gtmId: 'GTM-NSRCWTB',
};

TagManager.initialize(tagManagerArgs);
// Tawk();

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root'),
);

serviceWorker.register();
