import React, { Suspense, useState, useEffect } from 'react'
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  useLocation,
} from 'react-router-dom'
import ErrorBoundary from 'components/ErrorBoundary'
import PageNotFound from 'components/PageNotFound'
import IndexLayout from './layouts'
import Loader from './components/Loader'
import { Helmet } from 'react-helmet'
import { JSONScript } from '../scripts/jsonscript'
const loadable = (loader) => React.lazy(loader)

export const routes = [
  {
    path: '/',
    component: loadable(() => import('modules/home')),
    exact: true,
    bannerTitle: 'Home',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Index',
        url: '/home',
      },
    ],
  },
  {
    path: '/home',
    component: loadable(() => import('modules/home')),
    exact: true,
    bannerTitle: 'Home',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Index',
        url: '/home',
      },
    ],
  },
  {
    path: '/services',
    component: loadable(() => import('modules/services')),
    exact: true,
    bannerTitle: 'Services',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Services',
      },
    ],
  },
  {
    path: '/services/:id',
    component: loadable(() => import('modules/services/details')),
    exact: true,
    bannerTitle: 'Services',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Service Details',
      },
    ],
  },
  {
    path: '/doctors',
    component: loadable(() => import('modules/doctors-list')),
    exact: true,
    bannerTitle: 'Doctors',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Doctors',
      },
    ],
  },
  {
    path: '/doctors/:doc_name',
    component: loadable(() => import('modules/doctor-profile')),
    exact: true,
    bannerTitle: 'DOCTORS PROFILE',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'DOCTORS PROFILE',
      },
    ],
  },
  {
    path: '/gallery',
    component: loadable(() => import('modules/gallery')),
    exact: true,
    bannerTitle: 'Gallery',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Gallery',
      },
    ],
  },
  {
    path: '/gallery/:id',
    component: loadable(() => import('modules/gallery/details')),
    exact: true,
    bannerTitle: 'Gallery',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: ':id',
      },
    ],
  },
  {
    path: '/careers',
    component: loadable(() => import('modules/careers')),
    exact: true,
    bannerTitle: 'Careers',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Careers',
      },
    ],
  },
  {
    path: '/blogs',
    component: loadable(() => import('modules/blogs/list')),
    exact: true,
    bannerTitle: 'News & Events',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'News & Events',
      },
    ],
  },
  {
    path: '/blogs/:id',
    component: loadable(() => import('modules/blogs/details')),
    exact: true,
    bannerTitle: 'News & Events',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'News & Events',
      },
    ],
  },
  {
    path: '/testimonials',
    component: loadable(() => import('modules/testimonials')),
    exact: true,
    bannerTitle: 'Testimonials',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Testimonials',
      },
    ],
  },
  {
    path: '/contact-us',
    component: loadable(() => import('modules/contact-us')),
    exact: true,
    bannerTitle: 'Contact Us',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Contact Us',
      },
    ],
  },
  {
    path: '/success-stories',
    component: loadable(() => import('modules/success-stories')),
    exact: true,
    bannerTitle: 'Success Stories',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Success Stories',
      },
    ],
  },
  {
    path: '/about-us',
    component: loadable(() => import('modules/about-us')),
    exact: true,
    bannerTitle: 'About Us',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'About Us',
      },
    ],
  },
  // {
  //   path: '/online-payments',
  //   component: loadable(() => import('modules/online-payments/details')),
  //   exact: true,
  //   bannerTitle: 'Online Payments',
  //   bread: [
  //     {
  //       title: 'Home',
  //       url: '/home',
  //     },
  //     {
  //       title: 'Online Payments',
  //     },
  //   ],
  // },
  {
    path: '/pay-later-success',
    component: loadable(() => import('modules/online-payments/payment-status/PayLaterSuccess')),
    exact: true,
    bannerTitle: 'Pay Later Success',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Pay Later Success',
      },
    ],
  },
  {
    path: '/pay-later-failure',
    component: loadable(() => import('modules/online-payments/payment-status/PayLaterFailure')),
    exact: true,
    bannerTitle: 'Pay Later Failure',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Pay Later Failure',
      },
    ],
  },
  {
    path: '/payment-status-success',
    component: loadable(() => import('modules/online-payments/payment-status/PaymentStatusSuccess')),
    exact: true,
    bannerTitle: 'Payment Status Success',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Payment Status Success',
      },
    ],
  },
  {
    path: '/payment-status-failure',
    component: loadable(() => import('modules/online-payments/payment-status/PaymentStatusFailure')),
    exact: true,
    bannerTitle: 'Payment Status Failure',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Payment Status Failure',
      },
    ],
  },
  {
    path: '/billpayment-status-success',
    component: loadable(() => import('modules/online-payments/payment-status/BillPaymentSuccess')),
    exact: true,
    bannerTitle: 'Bill Payment Success',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Bill Payment Success',
      },
    ],
  },
  {
    path: '/billpayment-status-failure',
    component: loadable(() => import('modules/online-payments/payment-status/BillPaymentFailure')),
    exact: true,
    bannerTitle: 'Bill Payment Failure',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Bill Payment Failure',
      },
    ],
  },
  {
    path: '/consultation',
    component: loadable(() => import('modules/consultation-list')),
    exact: true,
    bannerTitle: 'Consultation',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Consultation',
      },
    ],
  },
  {
    path: '/consultation/:doctorid',
    component: loadable(() => import('modules/consultation-list')),
    exact: true,
    bannerTitle: 'Consultation',
    bread: [
      {
        title: 'Home',
        url: '/home',
      },
      {
        title: 'Consultation',
      },
    ],
  },
  {
    path: '/online-consultation',
    component: loadable(() => import('modules/pages')),
    exact: true,
    bannerTitle: 'Page',
    bread: [
      {
        title: 'Home Details',
        url: '/home',
      },
      {
        title: 'Page Details',
      },
    ],
  },
  {
    path: '/csr-policy',
    component: loadable(() => import('modules/csr-policy')),
    exact: true,
    bannerTitle: 'Page',
    bread: [
      {
        title: 'Home Details',
        url: '/home',
      },
      {
        title: 'Page Details',
      },
    ],
  },
  {
    path: '/pages/:id',
    component: loadable(() => import('modules/pages')),
    exact: true,
    bannerTitle: 'Page',
    bread: [
      {
        title: 'Home Details',
        url: '/home',
      },
      {
        title: 'Page Details',
      },
    ],
  },
]

// function withProps(Component, props) {
//   return function(matchProps) {
//     return <Component {...props} {...matchProps} />
//   }
// }

export default function RouterJ() {
  const location = useLocation()

  useEffect(() => {
    window.addEventListener('popstate', function (e) {
      console.log('url changed')
    })
  }, [])
  const [currentpath, setcurrentpath] = useState(location.pathname)
  console.log('location--->?>>>', location)
  // const { history } = this.props
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        {/* <BrowserRouter> */}
        <IndexLayout>
          <Helmet>
            {location.pathname == '/' ? (
              JSONScript(location.pathname).map((result, i) => {
                return (
                  <script type="application/ld+json">
                    {JSON.stringify(result)}
                  </script>
                )
              })
            ) : (
              <script type="application/ld+json">
                {JSON.stringify(JSONScript(location.pathname))}
              </script>
            )}
          </Helmet>
          <Switch>
            {/* <Route exact path="/" render={() => <Redirect to="/home" />} /> */}
            {routes.map((route) => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={() => <PageNotFound />} />
          </Switch>
        </IndexLayout>
        {/* </BrowserRouter> */}
      </Suspense>
    </ErrorBoundary>
  )
}

export function NoMatch() {
  const location = useLocation()

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  )
}
