import React from 'react'
import PropTypes from 'prop-types'

const NoList = React.memo(({ message }) => {
  return <div style={{textAlign:"center",width:"100%",fontWeight:"500"}} >{message}</div>
});

NoList.propTypes = {
  message: PropTypes.string
}

NoList.defaultProps = {
  message: 'No data available',
}

export default NoList
