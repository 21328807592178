import ModalBooking from "components/Modal/Modal-booking";
import Modal from "components/Modal";
import React from "react";
import { useState } from "react";
import BookingService from "api/booking";
import { notEmptyIsValid } from "Validators/authValidation";
import { useEffect } from "react";
import ErrorLabel from "components/UI/forms/errors/ErrorLabel";
import ErrorLabelSlots from "components/UI/forms/errors/ErrorLabelSlots";

function LabreportModal({ labModal, setLabModal }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [image, setImage] = useState();
  const [bill, setBill] = useState();
  const [labLoading, setLabLoading] = useState(false);
  const [billError, setBillError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);

  console.log("user and password", username, password);

  const API_URL = process.env.REACT_APP_API_URL;

  // useEffect(()=>{
  //  setBill();
  // },[])

  const otpTxtN = "";
  const otpCheck = "";

  const handleclose = () => {
    setLabModal(false);
    setBill();
    setUsername("");
    setPassword("");
    setBillError(false);
    setSuccess(false);
  };

  // const getLabDetails = async () => {
  //   setBill()
  //   setLabLoading(true)
  //   let url = `${API_URL}/api/v1/booking/get-lab-report-against-bill-credentials`;
  //   const before = "data:image/png;base64, ";

  //   return fetch(url, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     body: JSON.stringify({
  //       data: {
  //         username: username,
  //         password: password,
  //         otpTxtN: otpTxtN,
  //         otpCheck: otpCheck,
  //       },
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(`lab report',${before}${data.data}`);
  //       setImage(data.data);
  //       setBill(data.billNo);
  //       setLabLoading(false);
  //     });

  //   // .then((json) => {
  //   //   cache = json;
  //   // });
  // };

  useEffect(()=>{
    if(success){
    const timeoutId = setTimeout(() => {
      // Code to be executed after the timeout
      setLabModal(false)
      setSuccess(false)
    }, 3000); // 1000 milliseconds (1 second) in this example

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }

  },[success])

  let errorTimer = null;

  const clearErrors = () => {
    setError("");
    setPasswordError("");
  };

  useEffect(() => {
    if (error || passwordError) {
      errorTimer = setTimeout(() => clearErrors(), [1500]);
    }

    return () => clearTimeout(errorTimer);
  }, [error]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLabLoading(true);
    setBillError(false);
    setBill(false);

    if (!notEmptyIsValid(username)) {
      setLabLoading(false);
      setError("Username is required");
    }
    if (!notEmptyIsValid(password)) {
      setLabLoading(false);
      setPasswordError("Password is required");

      return;
    }
    const data = await BookingService.getLabDetails(username, password);
    console.log("lab report response", data);
    if (data) {
      setImage(data.data);
      setBill(data.billNo);
      setLabLoading(false);
    }
    if (data.status === "error") {
      setBillError(true);
      setErrorMsg(data.msg);
    }
  };

  useEffect(() => {
    if (image) {
      downloadPdf();
    }
  }, [image]);

  useEffect(() => {
    if (errorMsg) {
      const timer = setTimeout(() => {
        setBillError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMsg]);

  console.log("bill is", bill, image);

  const downloadPdf = () => {
    // window.open(`data:application/pdf;base64,${image}`,'_blank');
    // const linkSource = `data:application/pdf;base64,${image}`;
    // const downloadLink = document.createElement("a");
    // const fileName = "Lab_report.pdf";
    // downloadLink.href = linkSource;
    // downloadLink.download = fileName;
    // downloadLink.click();

    // Convert base64 PDF data to binary data
    const binaryData = atob(image);

    // Create an array buffer to store the binary data
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    // Convert binary data to array buffer
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the array buffer
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });

    // Create a data URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a download link
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = "Lab_report.pdf";

    // Trigger a click on the link to start the download
    a.click();

    // Release the object URL to free up resources
    URL.revokeObjectURL(blobUrl);
    setSuccess(true);
  };

  return (
    <Modal id="doctors-popup" handleModalClose={handleclose} isShow={labModal}>
      <form
        // className={`${classes.registerForm} ${hide && classes.hide}`}
        onSubmit={onSubmit}
      >
        <Modal.Body>
          {!success && (
            <div className="lab-div">
              <h3 className="lab-title">Lab Report Download</h3>
              <h3 className="download-lab">Download Your Lab Report Easily</h3>
              <div className="lab-details">
                <a
                  id="bill-status"
                  title="Lab report"
                  style={{ color: "red", textDecoration: "none" }}
                >
                  {billError ? errorMsg : ""}
                  {/* {billError ? "No Lab report Found" : ""} */}
                </a>
                <div className="lab-user-name">
                  <input
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    type="text"
                    name="username"
                    className="lab-username"
                    placeholder="Username"
                  />
                  {error && <ErrorLabelSlots> {error} </ErrorLabelSlots>}
                </div>
                <div className="lab-password">
                  <input
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type="password"
                    name="password"
                    className="lab-password"
                    placeholder="Password"
                  />
                  {passwordError && (
                    <ErrorLabelSlots> {passwordError} </ErrorLabelSlots>
                  )}
                </div>
                <button type="submit">Get Your Lab Report</button>
                <div className="bill-flex">
                  {/* <a id="bill-status" title="Lab report">
                {bill ? "BillNo :" + bill : ""}
              </a> */}

                  {/* <div
                className={`${bill ? "bill-download" : "bill-hide"}`}
                onClick={downloadPdf}
              >
                {bill ? "Download" : ""}
              </div> */}
                  <h3 className="details-from-bill">
                    Get your username and password from the bill for your lab
                    test.
                  </h3>
                  <div
                    className={`${
                      !labLoading ? "lab-loading-hide" : "lab-loading"
                    }`}
                  >
                    <img src="/img/loading-gif.gif" />
                    <p>Loading...</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {success && (
            <section className="lab-report-container">
              <div className="lab-report-success">
                <img src="/img/tick.svg" style={{ height: "200px" }} />
                <h3
                  style={{
                    fontSize: "24px",
                    fontWeight: 400,
                    color: "#000",
                    marginTop: "30px",
                  }}
                >
                  Lab Report Downloaded Successfully.
                </h3>
                {/* <TickCircle /> */}
              </div>
            </section>
          )}
        </Modal.Body>
      </form>
    </Modal>
  );
}

export default LabreportModal;
