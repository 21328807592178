import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { children, render } = this.props
    const { errorInfo, error } = this.state
    console.log('in here', errorInfo, error)
    // const { componentStack } = errorInfo
    if (errorInfo) {
      if (render){
        return render
      }
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {/* {componentStack} */}
          </details>
        </div>
      )
    }
    // Normally, just render children
    return children
  }
}

export default ErrorBoundary
