import { fetch } from "whatwg-fetch";
import { JSEncrypt } from "jsencrypt";
import forge from 'node-forge';

let cache = null;

const API_URL = process.env.REACT_APP_API_URL;
const accessToken = window.localStorage.getItem('accesstoken')

const getAPIBooking = async (id, datetouse) => {
  const endPoint = `doctor-available-slots/${id}`
  const mutatedendPoint = endPoint.replace("/", "%2F")
  let url = `${API_URL}/api/v1/booking/${mutatedendPoint}`;
  // let url = `/api/v1/doctor-available-slots/${id}`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
    body: JSON.stringify({
      data: {
        "date": datetouse, "length": "4", "start": "0"
      },
    }),
  })
    .then((response) => response.json())
    .then((data) => data.data)
  // .then((json) => {
  //   cache = json;
  // });
};



// const contactEncryption = async (data) => {
//   const publicKey= "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqll3mHoicnyleJyssrw9it3efjTQuYrzIyxN2uHegbZcFCyElNWGHPh7zOxrESLdnRlI8ehvuG1BHqwj4MadSGnuRXk7uL7aOlsajT4tKCh/Dwb2IozTPVjpAXA2ncOue/Nsh5T0jjBTaRWYa1CTTBBi/m85W6QPINYP/gtlnPpYoeLQUN78U6fMw4rcEEyzJ4AKCh8v5WdVloCMWQbpd94f3/uWAP0ES3JBDEmQbzU64a4kit/TCdXnB32yzynzypB+HpCwDCSw+DnmWFa/nbrvJZr4BByzpS/uyoLVPF6w2wi+3DkxcpdWoo1g1aWpj3z3gRt+F24LovI60+YvL1g6DHGGoXgn3pUeGckq7U5ihLBR3aqt/JbTZpb2IT9Q3D2eWbyglReCemvvcEoT+JUvK1g1DzTYT3iMiNMeOiJkYuY6zckFBZTT9jTdw0BH9mDPqWXzoZ8dZenCH4l4kilkw//Hwj56r0N16XEyoSnpgtzWl1YthlVcy4um+Ujc/bWqWaSL1+sTneMftuR46lbyIHaGDwush5cJs67nGXsuL4w1MJE1IiSZhcdja6+TuMrTgCKg1Wjbs9eF//einKCUW8Y1wkrDoBOSYRkbNmfX9rNzCUjXPay8crZK8sg48eNHnriVFFPNjcmHWVZ9oDVEQnOT2TpPPFnEuK74/9sCAwEAAQ==";
//   let key = null;
//   const encryption = openssl_public_encrypt(data,key,publicKey,OPENSSL_PKCS1_OAEP_PADDING);
//   return base64_encode(key)
// };



// const contactEncryption = async (data) => {

//   // const publicKey = `MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqll3mHoicnyleJyssrw9it3efjTQuYrzIyxN2uHegbZcFCyElNWGHPh7zOxrESLdnRlI8ehvuG1BHqwj4MadSGnuRXk7uL7aOlsajT4tKCh/Dwb2IozTPVjpAXA2ncOue/Nsh5T0jjBTaRWYa1CTTBBi/m85W6QPINYP/gtlnPpYoeLQUN78U6fMw4rcEEyzJ4AKCh8v5WdVloCMWQbpd94f3/uWAP0ES3JBDEmQbzU64a4kit/TCdXnB32yzynzypB+HpCwDCSw+DnmWFa/nbrvJZr4BByzpS/uyoLVPF6w2wi+3DkxcpdWoo1g1aWpj3z3gRt+F24LovI60+YvL1g6DHGGoXgn3pUeGckq7U5ihLBR3aqt/JbTZpb2IT9Q3D2eWbyglReCemvvcEoT+JUvK1g1DzTYT3iMiNMeOiJkYuY6zckFBZTT9jTdw0BH9mDPqWXzoZ8dZenCH4l4kilkw//Hwj56r0N16XEyoSnpgtzWl1YthlVcy4um+Ujc/bWqWaSL1+sTneMftuR46lbyIHaGDwush5cJs67nGXsuL4w1MJE1IiSZhcdja6+TuMrTgCKg1Wjbs9eF//einKCUW8Y1wkrDoBOSYRkbNmfX9rNzCUjXPay8crZK8sg48eNHnriVFFPNjcmHWVZ9oDVEQnOT2TpPPFnEuK74/9sCAwEAAQ==`

//   const publicKey = `MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqll3mHoicnyleJyssrw9
//   it3efjTQuYrzIyxN2uHegbZcFCyElNWGHPh7zOxrESLdnRlI8ehvuG1BHqwj4Mad
//   SGnuRXk7uL7aOlsajT4tKCh/Dwb2IozTPVjpAXA2ncOue/Nsh5T0jjBTaRWYa1CT
//   TBBi/m85W6QPINYP/gtlnPpYoeLQUN78U6fMw4rcEEyzJ4AKCh8v5WdVloCMWQbp
//   d94f3/uWAP0ES3JBDEmQbzU64a4kit/TCdXnB32yzynzypB+HpCwDCSw+DnmWFa/
//   nbrvJZr4BByzpS/uyoLVPF6w2wi+3DkxcpdWoo1g1aWpj3z3gRt+F24LovI60+Yv
//   L1g6DHGGoXgn3pUeGckq7U5ihLBR3aqt/JbTZpb2IT9Q3D2eWbyglReCemvvcEoT
//   +JUvK1g1DzTYT3iMiNMeOiJkYuY6zckFBZTT9jTdw0BH9mDPqWXzoZ8dZenCH4l4
//   kilkw//Hwj56r0N16XEyoSnpgtzWl1YthlVcy4um+Ujc/bWqWaSL1+sTneMftuR4
//   6lbyIHaGDwush5cJs67nGXsuL4w1MJE1IiSZhcdja6+TuMrTgCKg1Wjbs9eF//ei
//   nKCUW8Y1wkrDoBOSYRkbNmfX9rNzCUjXPay8crZK8sg48eNHnriVFFPNjcmHWVZ9
//   oDVEQnOT2TpPPFnEuK74/9sCAwEAAQ==`

//   const jsEncrypt = new JSEncrypt();
//   jsEncrypt.setPublicKey(publicKey);

//   return jsEncrypt.encrypt(data);
// }

const contactEncryption = async (data) => {

  // const publicKey = `MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqll3mHoicnyleJyssrw9it3efjTQuYrzIyxN2uHegbZcFCyElNWGHPh7zOxrESLdnRlI8ehvuG1BHqwj4MadSGnuRXk7uL7aOlsajT4tKCh/Dwb2IozTPVjpAXA2ncOue/Nsh5T0jjBTaRWYa1CTTBBi/m85W6QPINYP/gtlnPpYoeLQUN78U6fMw4rcEEyzJ4AKCh8v5WdVloCMWQbpd94f3/uWAP0ES3JBDEmQbzU64a4kit/TCdXnB32yzynzypB+HpCwDCSw+DnmWFa/nbrvJZr4BByzpS/uyoLVPF6w2wi+3DkxcpdWoo1g1aWpj3z3gRt+F24LovI60+YvL1g6DHGGoXgn3pUeGckq7U5ihLBR3aqt/JbTZpb2IT9Q3D2eWbyglReCemvvcEoT+JUvK1g1DzTYT3iMiNMeOiJkYuY6zckFBZTT9jTdw0BH9mDPqWXzoZ8dZenCH4l4kilkw//Hwj56r0N16XEyoSnpgtzWl1YthlVcy4um+Ujc/bWqWaSL1+sTneMftuR46lbyIHaGDwush5cJs67nGXsuL4w1MJE1IiSZhcdja6+TuMrTgCKg1Wjbs9eF//einKCUW8Y1wkrDoBOSYRkbNmfX9rNzCUjXPay8crZK8sg48eNHnriVFFPNjcmHWVZ9oDVEQnOT2TpPPFnEuK74/9sCAwEAAQ==`

  const publicKey = `-----BEGIN PUBLIC KEY-----
  MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqll3mHoicnyleJyssrw9
  it3efjTQuYrzIyxN2uHegbZcFCyElNWGHPh7zOxrESLdnRlI8ehvuG1BHqwj4Mad
  SGnuRXk7uL7aOlsajT4tKCh/Dwb2IozTPVjpAXA2ncOue/Nsh5T0jjBTaRWYa1CT
  TBBi/m85W6QPINYP/gtlnPpYoeLQUN78U6fMw4rcEEyzJ4AKCh8v5WdVloCMWQbp
  d94f3/uWAP0ES3JBDEmQbzU64a4kit/TCdXnB32yzynzypB+HpCwDCSw+DnmWFa/
  nbrvJZr4BByzpS/uyoLVPF6w2wi+3DkxcpdWoo1g1aWpj3z3gRt+F24LovI60+Yv
  L1g6DHGGoXgn3pUeGckq7U5ihLBR3aqt/JbTZpb2IT9Q3D2eWbyglReCemvvcEoT
  +JUvK1g1DzTYT3iMiNMeOiJkYuY6zckFBZTT9jTdw0BH9mDPqWXzoZ8dZenCH4l4
  kilkw//Hwj56r0N16XEyoSnpgtzWl1YthlVcy4um+Ujc/bWqWaSL1+sTneMftuR4
  6lbyIHaGDwush5cJs67nGXsuL4w1MJE1IiSZhcdja6+TuMrTgCKg1Wjbs9eF//ei
  nKCUW8Y1wkrDoBOSYRkbNmfX9rNzCUjXPay8crZK8sg48eNHnriVFFPNjcmHWVZ9
  oDVEQnOT2TpPPFnEuK74/9sCAwEAAQ==
  -----END PUBLIC KEY-----`

  const rsaPublicKey = forge.pki.publicKeyFromPem(publicKey)
  const plainText = '7356386380'
  const encrypted = rsaPublicKey.encrypt(data, 'RSA-OAEP')
  console.log('encripty', forge.util.encode64(encrypted))
  const base64encoded = forge.util.encode64(encrypted);
  return base64encoded;

}

const getToken = async (visit_id) => {
  let url = `${API_URL}/api/v1/booking/get-tokens-against-visit-detail`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
    body: JSON.stringify({
      data: {
        visit_id: visit_id,
      },
    }),
  })
    .then((response) => response.json())
    .then((data) => data.data)
  // .then((json) => {
  //   cache = json;
  // });
};

const getGenderList = async () => {
  // const accessToken = window.localStorage.getItem('accesstoken')
  let url = `${API_URL}/api/v1/booking/load-gender-master`;

  return fetch(url, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      // Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data.data)
  // .then((json) => {
  //   cache = json;
  // });
};

// const getPatientData = async (mobile) => {
//   // const accessToken = window.localStorage.getItem('accesstoken')
//   let url = `${API_URL}/api/v1/booking/patient-details-list`;

//   return fetch(url, {
//     method: "POST",
//     headers: {
//       // "Content-Type": "application/json",
//       // Accept: "application/json",
//       "Authorization": `Bearer ${accessToken}`,
//     },
//     body: JSON.stringify({
//       data: {
//         "mobile_number": mobile
//       },
//     }),
//   })
//     .then((response) => response.json())
//     .then((data) => data.data)
//   // .then((json) => {
//   //   cache = json;
//   // });
// };

const getPatientData = async (mobile, registerFormdata) => {
  const accessToken = window.localStorage.getItem('accesstoken')
  let url = `${API_URL}/api/v1/booking/patient-basic-details`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
    body: JSON.stringify({
      data: {
        "visit_id": registerFormdata.visit_id,
        "otp": registerFormdata.encrypted_otp,
        "mobile_number": registerFormdata.encrypted_mobile,
      },
    }),
  })
    .then((response) => response.json())
    .then((data) => data.data)
  // .then((json) => {
  //   cache = json;
  // });
};

const confirmBooking = async (data) => {
  // const accessToken = window.localStorage.getItem('accesstoken')
  let url = `${API_URL}/api/v1/booking/confirm-booking`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
    body: JSON.stringify({
      data: data
    }),
  })
    .then((response) => response.json())
    .then((data) => data)
};

const payNowConfirm = async (data) => {
  // const accessToken = window.localStorage.getItem('accesstoken')
  let url = `${API_URL}/api/v1/booking/pay-now-confirm`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
    body: JSON.stringify({
      data
    }),
  })
    .then((response) => response.json())
    .then((data) => data.data)
};

const getHashValue = async (data) => {
  // let url = `${API_URL}/api/v1/booking/payu-hashing`;
  let url = `${API_URL}/api/v1/payment/payu-hashing`;


  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
    body: JSON.stringify({
      txnid: data.transaction_id,
      amount: data.amount,
      productinfo: data.product_name,
      firstname: data.firstname,
      email: data.email,
      udf1: "WEB",
    }),
  })
    .then((response) => response.json())
    .then((data) => data.data)
}

const payLater = async (data) => {
  // const accessToken = window.localStorage.getItem('accesstoken')
  let url = `${API_URL}/api/v1/booking/booking-pay-later`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
    body: JSON.stringify({
      data
    }),
  })
    .then((response) => response.json())
    .then((data) => data)
};

const paymentSuccess = async (data) => {
  // const accessToken = window.localStorage.getItem('accesstoken')
  let url = `${API_URL}/api/v1/booking/booking-payment-success`;

  return fetch(url, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      // Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
    body: JSON.stringify({
      data: data
    }),
  })
    .then((response) => response.json())
    .then((data) => data.data)
};


const getLabDetails = async (username, password, otpTxtN = '', otpCheck = '') => {
  let url = `${API_URL}/api/v1/booking/get-lab-report-against-bill-credentials`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
    body: JSON.stringify({
      data: {
        username: username,
        password: password,
        otpTxtN: otpTxtN,
        otpCheck: otpCheck,
      },
    }),
  })
    .then((response) => response.json())
    .then((data) => data);
  // .then((json) => {
  //   cache = json;
  // });
};

const getBranchDetails = async () => {

  let url = `${API_URL}/api/v1/booking/branch-list`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem('accesstoken')}`,
    },
  })
    .then((response) => response.json())
    .then((data) => data.data);
  // .then((json) => {
  //   cache = json;
  // });
};





export default {
  getAPIBooking,
  getToken,
  getLabDetails,
  contactEncryption,
  getGenderList,
  getPatientData,
  confirmBooking,
  payNowConfirm,
  payLater,
  paymentSuccess,
  getBranchDetails,
  getHashValue,
};
