import React from 'react'
import { routes } from 'navigation/router'
import { withRouter, Link } from 'react-router-dom'



const Breadcrumb = props => {
  const { match } = props
  const { path } = match

  const index = routes.findIndex(i => i.path === path)

  if (index === -1 || routes[index].bread) return null

  const { bread } = routes[index]

  if (index < 0 || !bread) {
    return null
  }

  const l = bread.length
  const last = bread[l - 1]
  const firsts = bread.slice(0, l - 1)

  return (
    <div className="col-lg-6 heading-breadcrumb">
      <ol className="breadcrumb">
        {firsts.map(i => (
          <li key={i.title} className="breadcrumb-item">
            {i.url && <Link to={i.url}>{i.title}</Link>}
            {!i.url && i.title}
          </li>
        ))}
        <li className="breadcrumb-item active">{last.title}</li>
      </ol>
    </div>
  )
}

export default withRouter(Breadcrumb)
