import React, { Suspense, useEffect } from 'react';
import Router from 'navigation/router';
import { withRouter } from 'react-router';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  useLocation,
} from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import Loader from './navigation/components/Loader';
import TokenService from 'api/apiToken'

const App = props => {


  console.log(props);
  // let accessToken;


  const getAPItoken = async () => {
    const apiLoginData = await TokenService.getAPIAccessToken();
    console.log('api login data',apiLoginData)
    // window.localStorage.clear();
    window.localStorage.removeItem("accesstoken");
    if(apiLoginData.status == "success"){
    const accessToken = apiLoginData.responseArray.access_token;
    window.localStorage.setItem("accesstoken", accessToken);
    console.log("token in appjs", accessToken);
    }
  };

  useEffect(() => {
    getAPItoken()
  }, [props])


  return (
    <>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </>
  );
};

export default App;