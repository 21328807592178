import React from // Component,
//  Suspense
"react";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import WOW from "wow.js"; // use aos package instead

import HomeBanner from "components/HomeBanner";
import Banner from "components/Banner";
import Loader from "navigation/components/Loader";
import Header from "../components/Header";
import Footer from "../components/Footer";

// import Loader from '../components/Loader'

// const LoaderComp = (
//   <section>
//     <Loader />
//   </section>
// )

class IndexLayout extends React.PureComponent {
  componentDidMount() {
    const wow = new WOW();
    wow.init();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;
    if (location !== prevLocation) window.scrollTo(0, 0);
  }

  render() {
    const { children } = this.props;
    console.log('children props',this.props)

    let className;
    const { location } = this.props;
    const { pathname } = location;
    className = "inner-pages-wrapper";
    let banner = <Banner />;
    if (pathname === "/home" || pathname === "/") {
      className = "";
      banner = <HomeBanner />;
    }

    return (
      <>
        <Helmet titleTemplate="Sabine Hospital | %s" title="Sabine Hospital" />
        <div className={className}>
          {/* <Header /> */}

          <React.Suspense
            fallback={
              <>
                {/* {banner} */}
                <Loader
                  // spinning={false}
                  space
                />
              </>
            }
          >
            {children}
          </React.Suspense>
          {/* <React.Suspense fallback={<Footer noLink />}>
            <Footer />
          </React.Suspense> */}
        </div>
      </>
    );
  }
}

export default withRouter(IndexLayout);
