import React from 'react'
import classNames from 'classnames'
import styles from './style.module.scss'

const Loader = ({ spinning = true, fullScreen, space = false }) => {
  if (!space)
    return (
      <div
        className={classNames(styles.loader, {
          [styles.hidden]: !spinning,
          [styles.fullScreen]: fullScreen,
        })}
      />
    )
  return (
    <div style={{ position:'relative', margin: '10rem 0 20rem 0' }}>
      <div className={classNames(styles.loader, { [styles.hidden]: !spinning })} />
    </div>
  )
}

export default Loader
