import { fetch } from "whatwg-fetch";

let cache = null;

const API_URL = process.env.REACT_APP_API_URL;

const getAPIAccessToken = async () => {
  let url = `${API_URL}/api/v1/booking/web-site-login`;
  // let url = "api/v1/web-site-login";

  return await fetch(url, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Accept: "application/json",
    },
    // body: JSON.stringify({
    //   data: {
    //     grant_type: "client_credentials",
    //     client_id:
    //       "649c58d4e184ea0b924f9b41_nfs6ei72mqogw0g0ok404444wck8ckcc8oc8o440g0k0ws0cw",
    //     client_secret: "3hagfs3mhd0k0k0cwgkccssscgcw8800ggggw8kowwck00s440",
    //   },
    // }),
  })
    .then((response) => response.json())
  // .then((data) => data.data);
  // .then((json) => {
  //   cache = json;
  // });
};

export default {
  getAPIAccessToken,
};
