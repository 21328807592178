import React from 'react'
import BannerAnim, { Element } from 'rc-banner-anim'
import TweenOne from 'rc-tween-one'
import 'rc-banner-anim/assets/index.css'
import 'assets/styles/banner-style.css'


const { BgElement } = Element
class HomeBanner extends React.Component {
  render() {
   
    return (
      <BannerAnim
        prefixCls="banner-user banner"
        type="across"
        autoPlay
        autoPlaySpeed={5000}
        autoPlayEffect
      >
        <Element prefixCls="banner-user-elem" key="0">
          <BgElement
            key="bg"
            className="bg"
            style={{
              background: 'url(/resources/img/sabinehospital-banners/sabinehospital-banner-01.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <TweenOne className="banner-user-text" animation={{ y: 30, opacity: 0, type: 'from' }}>
            <div className="container">
              <div className="slide__content">
                <div
                  className="slide__text"
                  style={{
                    opacity: 1,

                    transform: 'translateX(0) rotate(-3deg)',
                  }}
                >
                  <h2 className="slide__text-heading">
                    Human Touch <span>Smile</span> among Childless Couple
                  </h2>
                </div>
              </div>
            </div>
          </TweenOne>
        </Element>
        <Element prefixCls="banner-user-elem" key="1">
          <BgElement
            key="bg"
            className="bg"
            style={{
              background: 'url(/resources/img/sabinehospital-banners/sabinehospital-banner-02.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <TweenOne className="banner-user-title" animation={{ y: 30, opacity: 0, type: 'from' }}>
            <div className="container">
              <div className="slide__content">
                <div
                  className="slide__text"
                  style={{
                    opacity: 1,

                    transform: 'translateX(0) rotate(-3deg)',
                  }}
                >
                  <h2 className="slide__text-heading">
                    Human Touch <span>Smile</span> among Childless Couple
                  </h2>
                </div>
              </div>
            </div>
          </TweenOne>
          {/* <TweenOne
            className="banner-user-text"
            animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
          >
            The Fast Way Use Animation In React
          </TweenOne> */}
          {/* <Thumb prefixCls="user-thumb" key="thumb" component={TweenOne}>
            {thumbChildren}
          </Thumb> */}
        </Element>
      </BannerAnim>
    )
  }
}

export default HomeBanner
