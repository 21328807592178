import React from 'react'

const Contact = () => {
  return (
    <div className="footer-contact-wrapper">
      <div className="container footer-phone-email">
        <div className="footer-contact-area">
          <div className="footer-phone-main">
            <span>
              <img src="/img/contact-icon.svg" />
            </span>
            <div className="footer-contact-content">
              <p>Call Us To</p>
              <h4>
                <a href="tel:0485-2838100">0485-2838100</a>
              </h4>
            </div>
          </div>
          </div>
          <div className="footer-email-area">
          {/* <div className="or-wrapper">-OR-</div> */}
          <div className="footer-mail-main">
          <span>
              <img src="/img/email-icon.svg"/>
            </span>
            <div className="footer-contact-content">
              <p>24/7 Email Support</p>
              <h4>
                <a href="mailto:feedback@sabinehospital.com">feedback@sabinehospital.com</a>
              </h4>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
