export const JSONScript = param => {
  console.log(param);
  const paramtoname = {
    services: 'Services',
    'services/ivf': 'Infertility Treatment',
    'services/pediatrics-neonatology-and-nicu': 'Pediatrics and Neonatology',
    'services/laparoscopy-endoscopy': 'Laparoscopy',
    'services/general-surgery-bariatric-surgery-and-paediatric-surgery':
      'General Surgery',
    'services/obstetrics-and-gynaecology': 'Obstetrics and Gynaecology',
    'services/orthopaedics-treatment': 'Orthopaedics Treatment',
    'services/feto-maternal-medicine': 'Feto Maternal Medicine',
    'services/child-development-centre': 'Child Development  Centre',
    'services/auxiliary-services': 'Auxiliary Services',
    'services/critical-care': 'Critical Care',
    '/doctors': 'Doctors',
    blogs: 'Blogs',
    testimonials: 'Testimonials',
    'success-stories': 'Success Stories',
    careers: 'Careers',
    gallery: 'Gallery',
  };

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  if (param == '/') {
    return [
      {
        '@context': 'http://www.schema.org',
        '@type': 'Hospital',
        name: 'Sabine Hospital - IVF Centre',
        url: BASE_URL + '/',
        logo:
        BASE_URL + '/resources/img/sabinehospital-logo.svg',
        image: BASE_URL + '/gallery/hospital-infrastructure',
        description:
          'Sabine IVF Centre provides male and female infertility treatment in kerala, ICSI, IUI.\n\n',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.4',
          reviewCount: '568',
        },
        address: {
          '@type': 'PostalAddress',
          streetAddress:
            'Sabine Hospital & Research Centre Pvt. Ltd, Pezhakkappilly P.O, Muvattupuzha, Kerala, India 686673',
          addressLocality: 'Muvattupuzha',
          addressRegion: 'Kerala',
          postalCode: '686673',
          addressCountry: 'India',
        },
        hasMap: 'https://g.page/sabine-hospital?share',
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '+91 4852838100',
          contactType: 'Customer service',
        },
      },
      {
        '@context': 'http://schema.org',

        '@type': 'LocalBusiness',

        name: 'SABINE HOSPITAL',

        image:
        BASE_URL + '/resources/img/sabinehospital-banners/sabinehospital-banner-01.jpg',

        telephone: '+91 485 2838100',

        email: 'feedback@sabinehospital.com',

        address: {
          '@type': 'PostalAddress',

          streetAddress:
            'Sabine Hospital and Research Centre, Pezhakkappilly P.O',

          addressLocality: 'Muvattupuzha, Ernakulam District',

          addressRegion: ', Kerala',

          addressCountry: 'India',

          postalCode: '686673',
        },

        openingHoursSpecification: {
          '@type': 'OpeningHoursSpecification',

          dayOfWeek: {
            '@type': 'DayOfWeek',

            name: 'Monday to Sunday',
          },
        },

        url: BASE_URL + '/',
      },
      {
        '@context': 'https://schema.org',

        '@type': 'Organization',

        name: 'Sabine Hospital',

        url: BASE_URL + '/',

        logo:
        BASE_URL + '/resources/img/sabinehospital-logo.svg',

        contactPoint: {
          '@type': 'ContactPoint',

          telephone: '+91 4852838100',

          contactType: 'customer service',

          contactOption: 'HearingImpairedSupported',

          areaServed: 'IN',

          availableLanguage: 'en',
        },

        sameAs: [
          'https://www.facebook.com/shrcmuvattupuzha/',

          'https://twitter.com/sabine_hospital/',

          'https://www.instagram.com/sabine_hospital/',

          'https://www.youtube.com/channel/UCTP1ClAeQs4bzh_uIVo60SQ',

          'https://in.linkedin.com/in/careers-sabines-2301261a0/',

          BASE_URL + '/',
        ],
      },
      {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        '@id': '#website',
        url: BASE_URL + '/',
        name: 'Sabine Hospital',
        potentialAction: {
          '@type': 'SearchAction',
          target: BASE_URL + '/?s={search_term_string}',
          'query-input': 'required name=search_term_string',
        },
      },
    ];
  } else {
    var capitalize;
    if (param.split('/').length === 2) {
      if (param.split('/')[1].split('-').length > 1) {
        capitalize = param
          .split('/')[1]
          .split('-')
          .map((result, i) => result.charAt(0).toUpperCase() + result.slice(1))
          .join(' ');
      } else {
        capitalize =
          param.split('/')[1].charAt(0).toUpperCase() +
          param.split('/')[1].slice(1);
      }
    } else {
      capitalize = param
        .split('/')[2]
        .split('-')
        .map((result, i) => result.charAt(0).toUpperCase() + result.slice(1))
        .join(' ');
    }
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: { '@id': BASE_URL + '/', name: 'Home' },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': `https:\/\/www.sabinehospital.com${param}\/`,
            name: capitalize,
          },
        },
      ],
    };
  }
};
