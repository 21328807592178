/* eslint-disable no-global-assign */
/* eslint-disable func-names */
import { fetch } from 'whatwg-fetch'
import entries from 'object.entries'

export const GlobalDebug = (function() {
  const savedConsole = console
  return function(debugOn = true, suppressAll) {
    const suppress = suppressAll || false

    if (debugOn === false) {
      console = {}
      console.log = function() {}
      if (suppress) {
        console.info = function() {}
        console.warn = function() {}
        console.error = function() {}
      } else {
        console.info = savedConsole.info
        console.warn = savedConsole.warn
        console.error = savedConsole.error
      }
    } else {
      console = savedConsole
    }
  }
})()

export const isEmpty = val => {
  if (!Object.entries) {
    entries.shim()
  }
  if (val === '' || val === null || val === undefined) return true
  if (val.constructor === Object && Object.entries(val).length === 0) return true
  if (val.constructor === Array && val.length === 0) return true
  return false
}

export const omit = (obj, omitKey) =>
  Object.keys(obj).reduce((result, key) => {
    if (key !== omitKey) {
      result[key] = obj[key]
    }
    return result
  }, {})

export async function callApi(...args) {
  try {
    const response = await fetch(...args)
    console.log(response)
    if (response.ok) {
      const a = await response.json()
      console.log(a)
      return a
    }
    if (!response.ok) {
      const a = await response.json()
      console.log(a)
      throw new Error(a.message || response.statusText)
    }
  } catch (err) {
    throw err
  }
  return null
}

export function getFormattedTime(dateJSON) {
  const date = new Date(dateJSON)
  const HH = (date.getHours() < 10 ? '0' : '') + date.getHours()
  const mm = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
  const ss = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()

  return `${HH}:${mm}:${ss}`
}

export function getFormattedDate(dateJSON, format) {
  const date = new Date(dateJSON)
  const DD = (date.getDate() < 10 ? '0' : '') + date.getDate()
  const MM = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1)
  let MMM = 'JAN'
  switch (MM) {
    case '01':
      MMM = 'Jan'
      break
    case '02':
      MMM = 'Feb'
      break
    case '03':
      MMM = 'Mar'
      break
    case '04':
      MMM = 'Apr'
      break
    case '05':
      MMM = 'May'
      break
    case '06':
      MMM = 'Jun'
      break
    case '07':
      MMM = 'Jul'
      break
    case '08':
      MMM = 'Aug'
      break
    case '09':
      MMM = 'Sep'
      break
    case '10':
      MMM = 'Oct'
      break
    case '11':
      MMM = 'Nov'
      break
    case '12':
      MMM = 'Dec'
      break
    default:
      MMM = 'Jan'
  }
  const YYYY = date.getFullYear()

  if (format === 'DD MMM YYYY') return `${DD}/${MMM}/${YYYY}`

  return `${DD}-${MM}-${YYYY}`
}
