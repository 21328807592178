import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
// import { routes } from 'navigation/router'
import Breadcrumb from "./Breadcrumb";

const Banner = (props) => {
  const { image, title } = props;
  // console.log('banner props', props)
  // const { image, location } = props
  // const { pathname } = location
  // const index = routes.findIndex(i => i.path === pathname)
  // if (index > -1) {
  //   const { bannerTitle } = routes[index]
  return (
    <section className="inner-banner">
      <img src="/img/banner-top.png" alt="" className="img-fluid" />
      <div className="page-name-breadcrumb">
        <div
          className={`container ${
            window.location.pathname.includes("/doctors/")
              ? "doc-detail-banner"
              : ""
          } ${
            window.location.pathname.includes("/doctors") &&
            !window.location.pathname.includes("/doctors/")
              ? "doc-banner"
              : ""
          }`}
        >
          <div className={`row banner-title-element ${window.location.pathname.includes("/doctors") &&
            !window.location.pathname.includes("/doctors/")
              ? "doc-banner-row"
              : ""
          } ${
            window.location.pathname.includes("/doctors/")
              ? "doc-detail-banner-row"
              : ""
          }`}>
            <div className="col-lg-6 heading-breadcrumb">
              <h1>{title}</h1>
            </div>
            <Breadcrumb />
          </div>
        </div>
      </div>
    </section>
  );
  // }
  // return null
};

Banner.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
};

Banner.defaultProps = {
  image:
    "/resources/img/sabinehospital-banners/sabine-hospital-about-banner-01.jpg",
  title: "",
};

export default withRouter(Banner);
