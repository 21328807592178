import React from 'react'
import Content from 'components/Content'
import { Link } from 'react-router-dom'
import './index.css'

const PageNotFound = () => {
  return (
    <>
      <Content>
        <div className="container not-found">
          <div className="error-wrapper">
            <figure>
              <img src="/resources/img/404.png" alt="404" />
            </figure>
            <article>
              <h3 className="error-head">Error 404</h3>
              <p className="error-para">
                {`Unfortunately, the page you're looking for does not exist!`}
              </p>
              <Link to="/">
                <div className="online-consultation-btn"> Back to home</div>
              </Link>
            </article>
          </div>
        </div>
      </Content>
    </>
  )
}

export default PageNotFound
