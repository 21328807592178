import { fetch } from "whatwg-fetch";

let cache = null;

/**
 *
 * @param {boolean} limit // to fetch without content
 */
const readServices = (limit = false) => {
  if (cache) {
    return cache;
  }
  throw getAllServices(limit);
};

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAllServices = (limit = false) => {
  console.log(limit);
  let url =
  `${BASE_URL}/api/services?status=active&sort=priorityOrder`;
  if (limit) url += "&limit=true";
  console.log(url);
  return fetch(url)
    .then((response) => response.json())
    .then((json) => {
      cache = json;
    });
};

// const getAllServicesWithDelay = () => {
//   return fetch('/api/services')
//     .then(response => response.json())
//     .then(
//       json =>
//         new Promise(resolve =>
//           setTimeout(() => {
//             cache = json
//             resolve()
//           }, 10000),
//         ),
//     )
// }

export default {
  readServices,
  getAllServices,
};
